"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "ruleForm",
    staticClass: "demo-ruleForm",
    attrs: {
      model: _vm.ruleForm,
      rules: _vm.rules,
      "label-width": "100px"
    }
  }, [_c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isEdit,
      expression: "isEdit"
    }],
    attrs: {
      label: "店铺",
      prop: "shop_name",
      "label-width": _vm.labelWidth
    }
  }, [_vm._v("\n            " + _vm._s(this.ruleForm.shop) + "\n        ")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "产品名称",
      prop: "name",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.ruleForm.active != "拒绝" && _vm.isEdit == true
    },
    model: {
      value: _vm.ruleForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "name", $$v);
      },
      expression: "ruleForm.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "扣费方式",
      prop: "cost_type",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.ruleForm.cost_type,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "cost_type", $$v);
      },
      expression: "ruleForm.cost_type"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("天")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 3
    }
  }, [_vm._v("周")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 2
    }
  }, [_vm._v("月")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "下单方式",
      prop: "order_mode",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.ruleForm.order_mode,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "order_mode", $$v);
      },
      expression: "ruleForm.order_mode"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("先用后付")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 2
    }
  }, [_vm._v("先付后用")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ruleForm.active != "拒绝" && _vm.isEdit == true && _vm.ruleForm.active != "待审核",
      expression: "ruleForm.active != '拒绝' && isEdit == true && ruleForm.active != '待审核'"
    }],
    attrs: {
      label: "上架状态",
      prop: "active",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-radio-group", {
    on: {
      change: _vm.activeChange
    },
    model: {
      value: _vm.ruleForm.active,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "active", $$v);
      },
      expression: "ruleForm.active"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("上架")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v("下架")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "成本金额",
      prop: "cost_money",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    attrs: {
      type: "number"
    },
    model: {
      value: _vm.ruleForm.cost_money,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "cost_money", $$v);
      },
      expression: "ruleForm.cost_money"
    }
  }, [_c("template", {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "押金(原首付款)",
      prop: "deposit",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    attrs: {
      type: "number"
    },
    model: {
      value: _vm.ruleForm.deposit,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "deposit", $$v);
      },
      expression: "ruleForm.deposit"
    }
  }, [_c("template", {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "下单类型",
      prop: "order_type",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-radio-group", {
    on: {
      change: _vm.updateRules
    },
    model: {
      value: _vm.ruleForm.order_type,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "order_type", $$v);
      },
      expression: "ruleForm.order_type"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 2
    }
  }, [_vm._v("租赁")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("缴费")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ruleForm.order_type == 2,
      expression: "ruleForm.order_type == 2"
    }],
    attrs: {
      label: "产品分类",
      prop: "goods_cate",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-cascader", {
    attrs: {
      options: _vm.options
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.optionsvalue,
      callback: function callback($$v) {
        _vm.optionsvalue = $$v;
      },
      expression: "optionsvalue"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "产品品牌",
      prop: "brand",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    model: {
      value: _vm.ruleForm.brand,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "brand", $$v);
      },
      expression: "ruleForm.brand"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "产品型号",
      prop: "model",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    model: {
      value: _vm.ruleForm.model,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "model", $$v);
      },
      expression: "ruleForm.model"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "扣款总额",
      prop: "money",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    attrs: {
      type: "number"
    },
    model: {
      value: _vm.ruleForm.money,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "money", $$v);
      },
      expression: "ruleForm.money"
    }
  }, [_c("template", {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _vm._v(" "), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ruleForm.order_type == 2,
      expression: "ruleForm.order_type == 2"
    }],
    attrs: {
      label: "扣款期数",
      prop: "cost_num",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    attrs: {
      type: "number"
    },
    model: {
      value: _vm.ruleForm.cost_num,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "cost_num", $$v);
      },
      expression: "ruleForm.cost_num"
    }
  }, [_c("template", {
    slot: "append"
  }, [_vm._v("期")])], 2)], 1), _vm._v(" "), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ruleForm.order_type == 2,
      expression: "ruleForm.order_type == 2"
    }],
    attrs: {
      label: "扣款规则明细",
      prop: "",
      "label-width": _vm.labelWidth
    }
  }, [_c("div", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("请点击【添加扣款规则】按钮，进行扣款规则的填写，")]), _vm._v(" "), _vm.ruleForm.cost_type == 1 ? _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("每次只能录入一天的规则，第一天含当日")]) : _vm.ruleForm.cost_type == 3 ? _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("每次只能录入一周的规则，7天计为1周(当期的最后一天扣款)，从签约当日开始计算，每7天为1周，可根据实际需求进行配置")]) : _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("每次只能录入一月的规则，每30天计为1月(当期的最后一天扣款)，从签约当日开始计算")])]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-top": "5px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.ruledialogVisible = true;
      }
    }
  }, [_vm._v("添加扣款规则")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-top": "5px"
    },
    attrs: {
      type: "default"
    },
    on: {
      click: _vm.clearRuleDate
    }
  }, [_vm._v("清空扣款规则")]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "添加扣款规则",
      visible: _vm.ruledialogVisible,
      width: "550px",
      "before-close": _vm.dialogBeforeClose,
      modal: false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.ruledialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    attrs: {
      model: _vm.ruleformdata,
      "label-width": "100px"
    }
  }, [_vm.ruleForm.cost_type == 1 ? _c("div", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("重要提示：第一天含当日，如需延后扣款请填写大于1的值")]) : _vm.ruleForm.cost_type == 3 ? _c("div", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("重要提示：第一周含当周，如需延后扣款请填写大于1的值")]) : _c("div", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("重要提示：第一月含当月，如需延后扣款请填写大于1的值")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "开始扣款时间"
    }
  }, [_c("el-input-number", {
    attrs: {
      "controls-position": "right",
      min: 1,
      max: 89
    },
    model: {
      value: _vm.ruleformdata.date,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleformdata, "date", $$v);
      },
      expression: "ruleformdata.date"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      margin: "10px 0"
    }
  }), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "扣款金额"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.ruleformdata.money,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleformdata, "money", $$v);
      },
      expression: "ruleformdata.money"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.dialogBeforeClose
    }
  }, [_vm._v("取 消")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addRule
    }
  }, [_vm._v("确 定")])], 1)], 1), _vm._v(" "), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.costRuledata
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "prop",
      label: "开始扣款时间",
      width: "width"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n                        第" + _vm._s(scope.row.date) + "\n                        "), _vm.ruleForm.cost_type == 1 ? _c("span", [_vm._v("天")]) : _vm.ruleForm.cost_type == 3 ? _c("span", [_vm._v("周")]) : _c("span", [_vm._v("月")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "prop",
      label: "扣款金额",
      width: "width"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n                        " + _vm._s(scope.row.money) + "\n                    ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "prop",
      label: "操作",
      width: "width"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.deleteCostRule(scope.row);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ruleForm.order_type == 2,
      expression: "ruleForm.order_type == 2"
    }],
    attrs: {
      label: "扣款规则",
      prop: "cost_rule",
      "label-width": _vm.labelWidth
    }
  }, [_vm._v("\n            " + _vm._s(_vm.ruleForm.cost_rule) + "\n        ")]), _vm._v(" "), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ruleForm.order_type == 2,
      expression: "ruleForm.order_type == 2"
    }],
    attrs: {
      label: "提前扣款天数",
      prop: "q_cost_day",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      min: 0
    },
    model: {
      value: _vm.ruleForm.q_cost_day,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "q_cost_day", $$v);
      },
      expression: "ruleForm.q_cost_day"
    }
  }, [_c("template", {
    slot: "append"
  }, [_vm._v("天")])], 2)], 1), _vm._v(" "), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ruleForm.order_type == 2,
      expression: "ruleForm.order_type == 2"
    }],
    attrs: {
      label: "到期买断开关",
      prop: "d_cost_type",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.ruleForm.d_cost_type,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "d_cost_type", $$v);
      },
      expression: "ruleForm.d_cost_type"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("开启")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v("关闭")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ruleForm.order_type == 2,
      expression: "ruleForm.order_type == 2"
    }],
    attrs: {
      label: "到期买断价",
      prop: "d_cost_money",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    attrs: {
      type: "number"
    },
    model: {
      value: _vm.ruleForm.d_cost_money,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "d_cost_money", $$v);
      },
      expression: "ruleForm.d_cost_money"
    }
  }, [_c("template", {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "备注提示信息",
      prop: "remark",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      disabled: _vm.ruleForm.active != "拒绝" && _vm.isEdit == true
    },
    model: {
      value: _vm.ruleForm.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "remark", $$v);
      },
      expression: "ruleForm.remark"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "待选参数名称",
      prop: "parameter",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "待选参数名称",
      disabled: _vm.ruleForm.active != "拒绝" && _vm.isEdit == true
    },
    model: {
      value: _vm.ruleForm.parameter,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "parameter", $$v);
      },
      expression: "ruleForm.parameter"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "产品待选参数",
      prop: "parameter_value",
      "label-width": _vm.labelWidth
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "默认待选参数",
      disabled: _vm.ruleForm.active != "拒绝" && _vm.isEdit == true,
      type: "textarea"
    },
    model: {
      value: _vm.ruleForm.parameter_value,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "parameter_value", $$v);
      },
      expression: "ruleForm.parameter_value"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "产品图片",
      prop: "good_img",
      "label-width": _vm.labelWidth
    }
  }, [_c("div", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("请上传 jpg/jpeg 格式的图片，暂不支持其他格式")]), _vm._v(" "), _c("input", {
    attrs: {
      type: "file",
      disabled: _vm.ruleForm.active != "拒绝" && _vm.isEdit == true
    },
    on: {
      change: _vm.handleFileChange
    }
  }), _vm._v(" "), _vm.imageUrl ? _c("div", {
    staticStyle: {
      width: "300px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: _vm.imageUrl,
      alt: "上传图片"
    }
  })]) : _vm._e()]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      "label-width": _vm.labelWidth
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submitForm("ruleForm");
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isEdit ? "修改产品" : "立即创建") + " ")]), _vm._v(" "), _c("el-button", {
    on: {
      click: function click($event) {
        return _vm.resetForm("ruleForm");
      }
    }
  }, [_vm._v("取消")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;