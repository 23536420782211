"use strict";

var _interopRequireDefault = require("/www/wwwroot/node/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("/www/wwwroot/node/node_modules/@babel/runtime-corejs2/helpers/objectWithoutProperties.js"));
var _defineProperty2 = _interopRequireDefault(require("/www/wwwroot/node/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
var _toConsumableArray2 = _interopRequireDefault(require("/www/wwwroot/node/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.number.constructor");
var _request = _interopRequireDefault(require("@/utils/request"));
var _excluded = ["shop_name"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var _default = exports.default = {
  name: 'GoodForm',
  props: {
    pk: Number,
    // 产品id
    isEdit: Boolean,
    //编辑还是新增
    is_day_nums: Boolean //是否一天多期
  },
  data: function data() {
    return {
      ruleForm: {
        shop_name: '',
        //店铺选择
        name: '',
        //产品名称
        cost_type: 1,
        //扣费方式
        day_num: 1,
        //自定义天数
        order_mode: 1,
        //下单方式
        active: 1,
        //上架状态
        cost_money: '',
        //成本金额
        deposit: 0,
        //押金（原首付款）
        order_type: 2,
        //下单类型
        goods_cate: '',
        //产品分类
        money: '',
        //扣款总额
        cost_num: 7,
        //扣款期数
        cost_rule: '',
        //扣款规则
        q_cost_day: 0,
        //提前扣款天数
        d_cost_type: 0,
        //到期买断开关
        d_cost_money: 0,
        //到期买断价
        remark: '',
        //备注提示信息
        parameter: '',
        //待选参数名称
        parameter_value: '',
        //产品待选参数
        // 以下为新增字段
        brand: '',
        //产品品牌
        model: '',
        //型号
        main_class: '',
        //一级分类
        sub_class: '',
        //二级分类
        good_img: '' //产品图片
      },
      labelWidth: '150px',
      rules: {
        name: [{
          required: true,
          message: '请输入产品名称',
          trigger: 'blur'
        }, {
          min: 2,
          message: '最少2个字符',
          trigger: 'blur'
        }, {
          validator: this.validateName,
          trigger: 'blur'
        }],
        // good_img: [
        //     { required: true, message: '请上传产品图片', trigger: 'change' }
        // ],
        cost_money: [{
          required: true,
          message: '请输入成本金额',
          trigger: 'blur'
        }, {
          validator: function validator(rule, value, callback) {
            if (!value) {
              callback(new Error('请输入成本金额'));
            } else if (isNaN(value) || parseInt(value, 10) < 1) {
              callback(new Error('最少1元'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }],
        money: [{
          required: true,
          message: '请输入扣款金额',
          trigger: 'blur'
        }, {
          validator: function validator(rule, value, callback) {
            if (!value) {
              callback(new Error('请输入扣款金额'));
            } else if (isNaN(value) || parseInt(value, 10) < 1) {
              callback(new Error('最少1元'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }]
      },
      currentCate: '',
      //产品分类
      options: [{
        value: '3C',
        label: '3C办公',
        children: [{
          value: '3c_mobile',
          label: '手机'
        }, {
          value: '3c_pc',
          label: '电脑'
        }, {
          value: '3c_camera',
          label: '摄影'
        }, {
          value: '3c_game_console',
          label: '游戏机'
        }, {
          value: '3c_office',
          label: '办公设备'
        }, {
          value: '3c_other',
          label: '3C-其他'
        }]
      }, {
        value: 'IOT',
        label: 'IOT',
        children: [{
          value: 'iot_auto_container',
          label: '售卖柜'
        }, {
          value: 'iot_stage',
          label: '驿站'
        }, {
          value: 'iot_other',
          label: 'IOT-其他'
        }]
      }, {
        value: 'NE',
        label: '新能源',
        children: [{
          value: 'ne_battery',
          label: '电池'
        }, {
          value: 'ne_car',
          label: '汽车'
        }, {
          value: 'ne_electric_car_logistic',
          label: '快递配送电动两轮车'
        }, {
          value: 'ne_electric_car_community',
          label: '城市共享电动两轮'
        }, {
          value: 'ne_other',
          label: '新能源-其他'
        }]
      }, {
        value: 'GENERAL',
        label: '泛行业',
        children: [{
          value: 'general_furniture',
          label: '家具'
        }, {
          value: 'general_tv',
          label: '家电'
        }, {
          value: 'general_house',
          label: '房屋'
        }, {
          value: 'general_decoration',
          label: '二手奢侈品或工艺品'
        }, {
          value: 'general_other',
          label: '泛其他'
        }]
      }, {
        value: 'EDUCATION',
        label: '教奇培训',
        children: [{
          value: 'education_vocational_training',
          label: '职业教培'
        }, {
          value: 'quality_education',
          label: '素质教育'
        }]
      }, {
        value: 'PETS',
        label: '宠物',
        children: [{
          value: 'adoption_installment',
          label: '颔养分期'
        }]
      }],
      activeZH: '上架',
      optionsvalue: [],
      selectedFile: null,
      imageUrl: null,
      flag: false,
      // 修改图片的标志
      costRuledata: [],
      //可视化扣款规则
      ruledialogVisible: false,
      // 控制规格弹窗是否显示
      ruleformdata: {
        date: 1,
        money: ''
      },
      costRuledata1: [],
      //补充后的扣款规则
      currentRowRule: '' //记录用户修改的当前行内容
    };
  },
  created: function created() {
    // 当是修改产品时才会请求数据
    // 新增不请求
    if (this.pk) {
      this.getList();
    }
  },
  computed: {
    // 扣款规则_字符串——done
    // costRule() {
    //     // 将cost_rule数组的内容提取出来，变成字符串
    //     const ruleStr = JSON.parse(this.ruleForm.cost_rule)
    //     if (Array.isArray(ruleStr)) {
    //         return ruleStr.join(',');
    //     }
    //     return ruleStr
    // },
    // 待选参数_字符串——done
    parmaValue: function parmaValue() {
      // 将parameter_value数组的内容提取出来，变成字符串
      var ruleStr = JSON.parse(this.ruleForm.parameter_value);
      if (Array.isArray(ruleStr)) {
        return ruleStr.join(',');
      }
      return ruleStr.trim();
    }
  },
  // 监视器
  watch: {
    // 监视下单类型的变化，不管用户选择租赁还是原本就是租赁，都要更新规则
    // 'ruleForm.order_type': 'updateRules'
  },
  methods: {
    // 清空扣款规则表格
    clearRuleDate: function clearRuleDate() {
      this.costRuledata = [];
    },
    // 关闭扣款规则输入弹窗
    dialogBeforeClose: function dialogBeforeClose() {
      this.ruledialogVisible = false;
      // 清空输入
      this.ruleformdata = {
        date: 1,
        money: ''
      };
    },
    // 确认添加扣款规则
    addRule: function addRule() {
      var _this = this;
      // 扣款金额不能为空
      if (!this.ruleformdata.money || this.ruleformdata.money == 0) {
        this.$message.error('扣款金额需大于0！');
        return;
      }

      // 天数最小为1
      if (this.ruleformdata.date < 1) {
        this.$message.error('天数最小为1天！');
        return;
      }

      // 金额必须为数字，不能包含英文字符和汉字
      if (!/^\d+(?:\|\d+)*$/.test(this.ruleformdata.money)) {
        this.$message.error('金额必须为数字');
        return;
      }

      //如果已有相同date的值，则不能重复push
      if (this.costRuledata.some(function (item) {
        return item.date === _this.ruleformdata.date;
      })) {
        this.$message.error('已有相同时间的规则，请修改时间！');
        return;
      }

      // 新输入的date必须大于已有的date
      if (this.costRuledata.length > 0 && this.ruleformdata.date <= this.costRuledata[this.costRuledata.length - 1].date) {
        this.$message.error('时间必须大于已有的时间！');
        return;
      }
      // 将输入push进入costRuledata数组
      this.costRuledata.push(this.ruleformdata);
      this.ruledialogVisible = false;
      this.ruleformdata = {
        date: 1,
        money: ''
      };
      this.computedRule();
    },
    // // 修改单条扣款规则
    // updateCostRule(data) {
    //     // 找到正确的索引
    //     const index = this.costRuledata.findIndex(item => item.date === data.date);
    //     if (index !== -1) {
    //         // 将 ruleformdata 的内容赋值给 ruleFormdata
    //         this.ruleformdata.date = data.date;
    //         this.ruleformdata.money = data.money;
    //         // 显示对话框
    //         this.ruledialogVisible = true;
    //         // 将 costRuledata 中 index 位置的内容替换成新输入的 ruleformdata 内容
    //         this.$set(this.costRuledata, index, { ...this.ruleformdata });
    //     } else {
    //         console.error('未找到对应的扣款规则');
    //     }
    // },
    deleteCostRule: function deleteCostRule(data) {
      var index = this.costRuledata.findIndex(function (item) {
        return item.date === data.date;
      });
      if (index !== -1) {
        // 将数组删除
        this.costRuledata.splice(index, 1);
        this.computedRule();
      }
    },
    // 计算扣款规则
    computedRule: function computedRule() {
      console.log(this.costRuledata);
      // 假设 costRuledata 初始值为 [{date:1,money:3},{date:4,money:5}]
      // let costRuledata = [{ date: 1, money: 3 }, { date: 4, money: 5 }];

      // 找到最大的 date 值
      var maxDate = Math.max.apply(Math, (0, _toConsumableArray2.default)(this.costRuledata.map(function (item) {
        return item.date;
      })));

      // 创建一个完整的数组，date 从 1 到 maxDate，money 默认为 0
      var completeCostRuledata = Array.from({
        length: maxDate
      }, function (_, index) {
        return {
          date: index + 1,
          money: 0
        };
      });

      // 将原始数据合并到完整数组中
      this.costRuledata.forEach(function (item) {
        completeCostRuledata[item.date - 1].money = item.money;
      });

      // 提取所有的 money 值，并用英文逗号拼接成一个字符串
      var moneyString = completeCostRuledata.map(function (item) {
        return item.money;
      }).join(',');
      console.log(completeCostRuledata);
      // console.log(moneyString);

      // 更新 costRuledata
      this.costRuledata1 = completeCostRuledata;

      // 更新 ruleForm.cost_rule
      this.ruleForm.cost_rule = moneyString;
      console.log(this.ruleForm.cost_rule);
    },
    handleFileChange: function handleFileChange(event) {
      this.flag = true;
      // 获取用户选择的图片
      this.selectedFile = event.target.files[0];

      // 图片格式 只能是 JPG/JPEG/PNG 格式!
      var isJPG = this.selectedFile.type === 'image/jpeg'; // 检查 JPG 格式
      // 图片大小 不能超过 5MB
      var isLt5M = this.selectedFile.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/JPEG 格式!');
        return;
      }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
        return;
      }

      // 在用户界面中显示用户选择的图片预览。
      console.log(this.selectedFile, 'selectedFile');
      this.imageUrl = URL.createObjectURL(this.selectedFile);
      console.log(this.imageUrl, 'imageUrl');
    },
    // 产品名称不能含有敏感词——done
    validateName: function validateName(rule, value, callback) {
      var sensitiveWords = ['e卡', '易卡', 'E卡', '一卡', 'e 卡', '易 卡', 'E 卡', '一 卡', 'e_卡', '易_卡', 'E_卡', '一_卡', '京东', '京 东', '京东E卡', '京东 E卡', '京东_E卡', '京东e卡', '京东_e卡', '京东 e卡', '东E'];
      // const sensitiveWords = ['京', '东', '卡', 'e', '易', '一'];
      // if (sensitiveWords.includes(value)) {
      //     callback(new Error('产品名不可用，请重新修改'));
      // } else {
      //     callback();
      // }
      // 创建一个正则表达式，用于匹配任何包含敏感词的字符串
      var sensitiveRegex = new RegExp(sensitiveWords.map(function (word) {
        return word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      }).join('|'), 'i');
      if (sensitiveRegex.test(value)) {
        callback(new Error('产品名不可用，请重新修改'));
        // 清空输入
        this.ruleForm.name = '';
        return;
      } else {
        callback();
      }
    },
    // 当下单类型改变时，更新规则(新增扣款规则，扣款期数，扣款总额的相关逻辑校验)——done
    updateRules: function updateRules() {
      if (this.ruleForm.order_type === 2) {
        if (this.is_day_nums) {
          // 扣款期数最少为7期
          this.$set(this.rules, 'cost_num', [{
            required: true,
            message: '请输入扣款期数',
            trigger: 'blur'
          }, {
            validator: function validator(rule, value, callback) {
              if (!value) {
                callback(new Error('请输入扣款期数'));
              } else if (isNaN(value) || parseInt(value, 10) < 7 || parseInt(value, 10) > 52) {
                callback(new Error('最少7期,最多51期'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }]);
          this.$set(this.rules, 'cost_rule', [{
            required: true,
            message: '请通过【添加扣款规则】录入信息，将自动生成此项内容',
            trigger: 'blur'
          },
          //  1.输入英文逗号,管道符，任意数字
          {
            pattern: /^[0-9,|]+$/,
            message: '扣费规则仅可输入数字，英文逗号，竖线',
            trigger: 'blur'
          },
          //  扣款规则的每个值最少为1
          {
            validator: this.validateCostRuleValue,
            trigger: 'blur'
          },
          //  2.扣款规则数值总和 = 扣款总额
          {
            validator: this.validateCostMoney,
            trigger: 'blur'
          },
          //  3.扣款期数等于扣款规则数
          {
            validator: this.validateCostRule,
            trigger: 'blur'
          }]);
        } else {
          // 扣款期数最少为7期
          this.$set(this.rules, 'cost_num', [{
            required: true,
            message: '请输入扣款期数',
            trigger: 'blur'
          }, {
            validator: function validator(rule, value, callback) {
              if (!value) {
                callback(new Error('请输入扣款期数'));
              } else if (isNaN(value) || parseInt(value, 10) < 7 || parseInt(value, 10) > 52) {
                callback(new Error('最少7期,最多51期'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }]);
          this.$set(this.rules, 'cost_rule', [{
            required: true,
            message: '请通过【添加扣款规则】录入信息，将自动生成此项内容',
            trigger: 'blur'
          },
          //  1.输入英文逗号和任意数字
          {
            pattern: /^[0-9,]+$/,
            message: '扣费规则仅可输入数字和英文逗号',
            trigger: 'blur'
          },
          //  扣款规则的每个值最少为1
          {
            validator: this.validateCostRuleValue,
            trigger: 'blur'
          },
          //  2.扣款规则数值总和 = 扣款总额
          {
            validator: this.validateCostMoney,
            trigger: 'blur'
          },
          //  3.扣款期数等于扣款规则数
          {
            validator: this.validateCostRule,
            trigger: 'blur'
          }]);
        }
      } else {
        this.$set(this.rules, 'cost_num', []);
        this.$set(this.rules, 'cost_rule', []);
      }
      this.$refs.ruleForm.clearValidate(['cost_num']);
      this.$refs.ruleForm.clearValidate(['cost_rule']);
    },
    // 扣款规则的每个值最少为1——done
    validateCostRuleValue: function validateCostRuleValue(rule, value, callback) {
      // value为扣款规则
      // 否则按照原逻辑处理
      // 使用英文逗号或竖线

      var arr = value.split(/[,\|]/).map(Number);
      if (arr.every(function (item) {
        return item >= 0;
      })) {
        callback();
      } else {
        callback(new Error('扣款规则金额最少为0元'));
      }
    },
    // 扣款规则数值相加不等于扣款总额——done
    validateCostMoney: function validateCostMoney(rule, value, callback) {
      // value为扣款规则
      // 否则按照原逻辑处理
      var sum = value.split(/[,\|]/).map(Number).reduce(function (acc, curr) {
        return acc + curr;
      }, 0);
      if (sum == this.ruleForm.money) {
        callback();
      } else {
        callback(new Error('扣款规则数值总和与扣款总额不一致'));
      }
    },
    // 扣款规则数和扣款期数不一致——done
    validateCostRule: function validateCostRule(rule, value, callback) {
      var num = 0;
      value.split(/[,\|]/).map(function (item) {
        if (item > 0) {
          num++;
        }
      });
      console.log(num);
      // value为扣款规则
      // 扣款期数等于扣款规则数
      if (num != this.ruleForm.cost_num) {
        callback(new Error('扣款期数与扣款规则数不一致'));
      } else {
        callback();
      }
    },
    getCharge: function getCharge() {
      var _this2 = this;
      (0, _request.default)({
        url: "/system/shopConfig/getConfig/",
        method: 'get',
        params: {
          shop_id: this.$user.data.shop
        }
      }).then(function (res) {
        if (res.data) {
          _this2.is_day_nums = res.data.is_day_nums;
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 提交表单
    submitForm: function submitForm(formName) {
      var _this3 = this;
      this.updateRules();
      if (!this.selectedFile) {
        alert('请上传图片!!');
        return;
      }
      this.ruleForm.good_img = this.selectedFile;
      // 整理扣款规则，补0
      this.computedRule();
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this3.isEdit) {
            // 修改产品的逻辑（自定义事件名，小写或-连接，不能小驼峰命名）
            _this3.$emit('edit-good', _objectSpread(_objectSpread({}, _this3.ruleForm), {}, {
              good_img: _this3.selectedFile,
              flag: _this3.flag
            }));
          } else {
            // 添加产品的逻辑
            // 整理参数，将shop_name属性排除，上架状态传递中文，默认是待审核
            var _this3$ruleForm = _this3.ruleForm,
              shop_name = _this3$ruleForm.shop_name,
              rest = (0, _objectWithoutProperties2.default)(_this3$ruleForm, _excluded);
            var data = Object.assign({}, rest);
            console.log(data);
            _this3.$emit('add-good', _objectSpread(_objectSpread({}, data), {}, {
              good_img: _this3.selectedFile
            }));
          }
        } else {
          alert('提交失败!!');
          return false;
        }
      });
    },
    // 取消重置按钮——done
    resetForm: function resetForm(formName) {
      if (this.isEdit) {
        // 通知父组件取消按钮被点击
        this.$emit('cancel');
        // 清空输入的数据
        this.$refs[formName].resetFields();
        // 重新获取数据
        this.getList();
      } else {
        // 通知父组件取消按钮被点击
        this.$emit('cancel');
        // 清空输入的数据
        this.$refs[formName].resetFields();
      }
    },
    // 产品分类——done
    handleChange: function handleChange() {
      //  一级分类
      this.ruleForm.main_class = this.optionsvalue[0];
      // // // 二级分类
      this.ruleForm.sub_class = this.optionsvalue[1];
    },
    // 上架状态——done
    activeChange: function activeChange(val) {
      if (val === 0) {
        this.activeZH = '下架';
      } else {
        this.activeZH = '上架';
      }
    },
    // 根据id获取产品详情——done
    getList: function getList() {
      var _this4 = this;
      this.listLoading = true;
      (0, _request.default)({
        url: "/Goods/".concat(this.pk, "/"),
        method: 'get',
        params: {
          shop: this.$user.data.shop
        }
      }).then(function (response) {
        if (response.data) {
          // 保存数据
          _this4.ruleForm = response.data;
          // 处理数据格式
          _this4.ruleForm.active = response.data.active === "下架" ? 0 : 1;
          _this4.ruleForm.brand = response.data.brand === "自有品牌" ? "" : response.data.brand;
          _this4.ruleForm.model = response.data.model === "无" ? "" : response.data.model;

          // 后端返回的是数组，必须转换成字符串
          // this.ruleForm.cost_rule = this.costRule
          _this4.moneyStringtoArray();
        }
        _this4.listLoading = false;
      });

      // 请求图片
      (0, _request.default)({
        url: "/system/goods/goods_image_get/?goods_id=".concat(this.pk),
        method: 'get'
      }).then(function (response) {
        // 保存为base64格式
        _this4.imageUrl = "data:image/png;base64,".concat(response.data);
        _this4.selectedFile = _this4.imageUrl;
      });
    },
    moneyStringtoArray: function moneyStringtoArray() {
      var _this5 = this;
      // 将返回的字符串转换成数组，放到costRuledata中
      var arr = this.ruleForm.cost_rule.split(',');
      console.log(arr);
      arr.map(function (item, index) {
        _this5.costRuledata.push({
          date: index + 1,
          // date 从 1 开始
          money: item // 将 money 转换为数字
        });
      });

      // 将0的过滤掉
      this.costRuledata = this.costRuledata.filter(function (item) {
        return item.money != 0;
      });
    }
  }
};